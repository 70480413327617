import { ROUTE_KEY_TYPE } from '@/shared/utils/constants/common';
import { ROUTE_NAMES } from '@/shared/utils/constants/router';
import { depositsRoutes } from '@/app/router/deposits.ts';
import { additionalRoutes } from './additional';
import { currencyRoutes } from './currency';
import { documentsRoutes } from './documents';
import { inquiriesRoutes } from './inquiries';
import { onboardingRoutes } from './onboarding';
import accountAndCardsRoutes from '@/sections/accounts-and-cards/app/router';
import { directoriesRoutes } from '@/sections/directories/app/router';
import settingsRoutes from '@/sections/settings/app/router';
import statementsRoutes from '@/sections/statements/app/router';

const routes = [
  {
    path: '/',
    name: ROUTE_NAMES.system.rootLayout,
    component: () => import('@/app/layouts/RootLayout.vue'),
    children: [
      {
        path: '/',
        name: ROUTE_NAMES.system.authGuard,
        component: () => import('@/app/guards/auth/AuthGuard.vue'),
        children: [
          {
            path: '/',
            name: ROUTE_NAMES.system.defaultLayout,
            component: () => import('@/app/layouts/default-layout'),
            children: [
              {
                path: '/',
                name: 'main',
                meta: {
                  keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
                },
                component: () => import('@/pages/MainPage.vue')
              },
              {
                path: '/all-services',
                name: 'all-services',
                meta: {
                  keyName: ROUTE_KEY_TYPE.WORK_WITH_DOCUMENTS
                },
                component: () => import('@/pages/all-services/IndexPage.vue')
              },
              ...documentsRoutes,
              ...additionalRoutes,
              ...accountAndCardsRoutes,
              ...settingsRoutes,
              ...inquiriesRoutes,
              ...statementsRoutes,
              ...currencyRoutes,
              ...depositsRoutes,
              ...onboardingRoutes,
              ...directoriesRoutes
            ]
          }
        ]
      }
    ]
  },

  // Always leave this as last one,
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/app/layouts/RootLayout.vue'),
    children: [
      {
        path: '/:catchAll(.*)*',
        component: () => import('@/app/guards/auth/AuthGuard.vue'),
        children: [
          {
            path: '/:catchAll(.*)*',
            name: 'not-found',
            component: () => import('@/app/layouts/default-layout')
          }
        ]
      }
    ]
  }
];

export default routes;
