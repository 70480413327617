import { AxiosError, AxiosResponse } from 'axios';
import { PLATFORM_KEY_TYPE } from '@/shared/utils/constants/global.config.ts';
import { IApiError, IApiErrorData } from '../api/manager/managers.types';
import { PaymentCardStatuses } from './business.types';
import { ICurrency } from './currency.types';
import { CardsInfo } from './report';

export interface TabOption<T = string> {
  label: string;
  value: T;
  count?: string | number;
  show?: boolean;
}

export interface IBranch {
  id: number;
  code: string;
  name: string;
}

export interface IPaymentState {
  id: number;
  name: string;
}

export interface PaymentCardMapped {
  maskedCard: string;
  cardAccountNumber: string;
  embossedChName: string;
  receiverBranch: string;
  receiverBranchName?: string;
  receiverAccount: string;
  receiverName: string;
  accountName: string;
  label: string;
  value: CardsInfo;
  state: PaymentCardStatuses;
  stateName: string;
  balance: number,
  expirationDate: string;
  currency: ICurrency;
}

export interface PaymentCard {
  maskedCard: string;
  cardAccountNumber: string;
  embossedChName: string;
  accountName: string;
  receiverBranch: string;
  receiverBranchName?: string;
  receiverAccount: string;
  receiverName: string;
  balance: number;
  expirationDate: string;
  currency: ICurrency
  state: PaymentCardStatuses;
  stateName: string;
}

export interface ICardRequisites {
  receiverBranch: string;
  receiverAccount: string;
  receiverName: string;
}

export type IBusinessBranch = {
  branch: string | null;
  branchName: string | null;
  businessCode: string | null;
  name: string;
  inn: string;
  directorFullName: string;
  accounterFullName: string;
  postAddress: string;
  phone: string;
  fax: string | null;
  partnerId: number | null;
  pinfl: string | null;
};
export enum ModuleKeys {
  SWIFT = 'SWIFT',
  DEFAULT = 'DEFAULT'
}

export enum ApprovalLevels {
  LEVEL_0,
  LEVEL_1,
  LEVEL_2,
}

export enum STAGE_TYPES {
  APPROVE = 1,
  SIGN,
}

export type UserSettings = {
  userId: number,
  approvalLevel?: number
}

export type SettingsModule = {
  moduleKey: ModuleKeys,
  isMultiLevelApproval: boolean,
  maxApprovalLevel: ApprovalLevels;
  usersForApproval?: UserSettings[],
  usersForSigning?: UserSettings[]
}

export type IBusinessSettings = {
  approvalSettings: {
    modules: SettingsModule[];
  },
  esbModules: string[]
};

export interface IEmployee {
  rn?: number;
  id?: number;
  branch?: string;
  employeeCode: string;
  account: string;
  fio: string;
  amount: number;
  state?: number;
  maskedCard?: string;
  businessCode?: string;
  embossedName?: string;
  passport?: string;
  cardStateId: PaymentCardStatuses;
  cardStateName: string;
}

export interface Permissions {
  canApprove: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canRead: boolean;
  canSign: boolean;
}

export interface Approval {
  stageType: STAGE_TYPES;
  level?: ApprovalLevels;
  isCompleted: boolean;
  items: ApprovalItem[];
}

export interface ApprovalItem {
  userId: number;
  fio: string;
  dateTime: string;
}

export interface IResponseData<T = {}> {
  item: T;
  items: T[];
  totalCount: number;
  totalPages: number;
  error: boolean | IApiErrorData;
  result?: unknown;
  meta?: {
    permissions: Permissions,
    approval: Approval[]
  }
}

export type ApiErrorResponse =
  | Partial<AxiosResponse<IResponseData>>
  | AxiosError<IApiError>
  | void;

export interface ICertificate {
  serialnumber: string;
  thumbprint: string;
}

export interface ICertificates {
  certInfos: ICertificate[];
}

export interface ISign {
  signedMsg: string;
}

export interface ISignPayloadObject {
  objectId?: number | string;
  textToSign?: string;
  signature?: string;
}

export interface ISignPayload {
  keyType: PLATFORM_KEY_TYPE;
  objects: ISignPayloadObject[];
}

export interface SignAndSendData {
  id: number | string;
  textToSign: string;
}

export interface ISignResponse {
  fail: number;
  success: number;
}

export interface IAcceptResponse extends ISignResponse {}

export interface IEpassSignature {
  type: number;
  serialNumber: string;
}

export interface IBusinessDay {
  b2Description: string;
  b2OperDayState: number;
  branch: string;
  documentState: boolean;
  nciDescription: string;
  reportState: boolean;
  curDate: string;
  nextDate: string;
  nciOperDayState: OperationDayStates;
  state: string;
}

export interface ICountry {
  id: number;
  isoId: string;
  name: string;
  sName: string;
}

export interface IReport {
  reportId: string;
}

export interface IReportFile {
  base64String: string | null;
}

export interface IPagination {
  pageNumber: number;
  pageSize: number;
}

export interface IBase64FileResponse {
  base64String: string;
}

export enum OperationDayStates {
  Closed,
  Open,
}

export enum ApiVersions {
  v1 = '1.0',
  v2 = '2.0',
  v3 = '3.0',
  v4 = '4.0',
  v5 = '5.0'
}

export enum UserTypes {
  business = 'business/',
  agent = 'agent/'
}

export enum ScreenJumpTypes {
  business = 'business',
  partner = 'partner'
}

export enum CardProviders {
  uzcard,
  humo,
  visa,
  mastercard,
  unionpay,
  other
}

export const enum DirectionType {
  'IN' = 'in',
  'OUT' = 'out',
  'BETWEEN' = 'between',
}
